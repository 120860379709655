.footer {
    background-color: #222;
    color: #eee;
}

.navbar-custom .navbar-nav .nav-link {
    color: #222; 
}
.navbar-custom .navbar-brand {
    color: #222; 
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:active {
  text-decoration: underline;
}

.navbar-light .navbar-nav .nav-item.dropdown .btn:active,
.navbar-light .navbar-nav .nav-item.dropdown .btn:focus,
.navbar-light .navbar-nav .nav-item.dropdown .btn:hover {
    color: #fff; /* Set your desired light text color */
    text-decoration: none;
}

/* 
.navbar-custom {
    background-color: #fff;
}

.navbar-custom .navbar-brand {
    color: #222; 
}

.navbar-custom .navbar-toggler-icon {
    background-color: #000; 
}
*/

.dark-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 1;
}

.dark-overlay > * {
    position: relative;
    z-index: 2;
}

.tour-link, .tour-link * { /* Selects the anchor tag and all its children */
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Optional: keeps the text color consistent */
}

.badge-flavor-small {
    background-color: #66d9e8;
    color: #333333; /* Dark Gray */
}

.badge-flavor-medium {
    background-color: #ffc107;
    color: #0d47a1; /* Dark Blue */
}

.badge-flavor-large {
    background-color: #9c27b0;
    color: #f5f5f5; /* Light Gray */
}

.badge-adventure-couch {
    background-color: #ff7043;
    color: #ffffff; /* White */
}

.badge-adventure-inbetween {
    background-color: #8c6d62;
    color: #fff8e1; /* Cream White */
}

.badge-adventure-extreme {
    background-color: #26a69a;
    color: #002171; /* Dark Navy */
}

.badge-adventure-ultra {
    background-color: #3f51b5;
    color: #ffeb3b; /* Light Yellow */
}

.carousel-background {
    background-color: #333333; /* Or any darker shade of gray you prefer */
}

.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates three columns */
    gap: 10px; /* Adjust the gap as needed */
}

.form-check {
    display: flex;
    align-items: center;
}

.form-check-input {
    margin-right: 5px; /* Space between checkbox and label */
}

.badge-breakfast {
    background-color: #ff7043;
    color: #ffffff; /* White */
}

.badge-coffee {
    background-color: #8c6d62;
    color: #fff8e1; /* Cream White */
}
.badge-drinks {
    background-color: #26a69a;
    color: #002171; /* Dark Navy */
}

.badge-dinner {
    background-color: #3f51b5;
    color: #ffeb3b; /* Light Yellow */
}

.badge-lunch {
    background-color: #493131;
    color: #ffffff; /* White */
}

